import axios from '@/plugins/axios';

class RCSService {
  send(data) {
    this.data = data;
    return axios('v1').post('rcs/messages', this.data);
  }

  getAgent(data) {
    this.data = data;
    return axios('v1').get('rcs/agent', this.data);
  }

  getAgents(data) {
    this.data = data;
    return axios('v1').get('google-business', this.data);
  }

  getProducts(data) {
    this.data = data;
    return axios().get('rcs/products', { params: this.data }).then((response) => response.data);
  }

  chart(days) {
    this.a = 'a';
    return axios().get(`rcs/messages/charts?days=${days}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getInsights(data) {
    this.data = data;
    return axios().post('rcs/messages/insights', this.data).then((response) => response.data);
  }

  getCampaigns(data) {
    this.data = data;
    return axios().get('rcs/campaigns', { params: this.data }).then((response) => response.data);
  }

  getMessages(data) {
    this.data = data;
    return axios('v1').post('rcs/messages/list', this.data);
  }

  createNewTemplate(data) {
    this.data = data;
    return axios('v1').post('rcs/templates', this.data);
  }

  updateTemplate(data) {
    this.data = data;
    return axios('v1').put('rcs/templates', this.data);
  }

  deleteTemplate(account, id) {
    this.data = null;
    return axios('v1').delete(`rcs/templates/${account}/${id}`, this.data);
  }

  getTemplates(data) {
    this.data = data;
    return axios().post('rcs/templates/list', this.data);
  }

  getMessage(id) {
    this.id = id;
    return axios().get(`rcs/messages/${id}`);
  }

  deleteCampaign(campaignId) {
    this.campaignId = campaignId;
    return axios().delete(`rcs/campaigns/${campaignId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  filterMessages(data) {
    this.data = data;
    return axios().get('rcs/messages', { params: this.data }).then((response) => response.data);
  }

  createAgent(data) {
    this.data = data;
    return axios('v1').post('google-business', this.data);
  }
}

export default new RCSService();
