import Vue from 'vue';
import Api from '@/plugins/api';
import LiveService from '@/services/live.service';
import NotificationService from '@/services/notification.service';
import Dropdown from '@/directives/Dropdown';
import Dragglable from '@/directives/Dragglable';
import VModal from 'vue-js-modal';
// import moment from 'moment';
import moment from 'moment-timezone';
import VueMask from 'v-mask';
import VTooltip from 'v-tooltip';
import VueEasyLightbox from 'vue-easy-lightbox';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import money from 'v-money';
import VueNumeric from 'vue-numeric';
import DateRangePicker from '@gravitano/vue-date-range-picker';
// import VueRecord from '@codekraft-studio/vue-record';
import CKEditor from 'ckeditor4-vue';
import Chat from '@/components/chat-widget';
import Clipboard from 'v-clipboard';
import StringMask from 'string-mask';
import * as VueGoogleMaps from 'vue2-google-maps';
import Stripo from 'stripo.email';
import VueFab from 'vue-float-action-button';
import { Sketch as ColorPicker } from 'vue-color';
// import 'vue-audios/dist/vue-audios.css';
// import VueAudios from 'vue-audios';
import VueApexCharts from 'vue-apexcharts';
import vueCustomScrollbar from 'vue-custom-scrollbar';
import PrimeVue from 'primevue/config';

import WhatsAppMetaAccounts from '@/whatsapp';

import App from './App.vue';
import Rebranding from './plugins/rebranding';
import router from './router';
import store from './store';
import Toast from './plugins/toast';
// import Tooltip from './plugins/tooltip';
import Modal from './plugins/modal';
import i18n from './i18n';
// import Live from './plugins/live';
import FFmpeg from './plugins/FFmpeg';
import DOMAreaSelection from './plugins/DOMAreaSelection';

// Data Filters
import ElapsedFilter from './filters/elapsed';
import CurrencyFilter from './filters/currency';
import CurrencyFilter4D from './filters/currency4D';
import Date from './filters/date';
import Phone from './filters/phone';
import RelativeDate from './filters/relativeDate';
import RelativeDateUTC from './filters/relativeDateUTC';
import MinifyUuid from './filters/minifyUuid';
import UCFirst from './filters/ucFirst';
import Basename from './filters/basename';
import FileExtension from './filters/fileExtension';
import StrToColor from './filters/strToColor';

import ClickOutside from './directives/ClickOutside';
import facebook from './plugins/facebook';

Vue.use(facebook.init(), {
  appId: '1048315669955915',
  autoLogAppEvents: true,
  xfbml: true,
  version: 'v19.0',
});
// Vue.use(VueAudios);
Vue.use(PrimeVue);
Vue.use(Api);
Vue.use(VueApexCharts);
Vue.use(VueFab);
Vue.use(DateRangePicker);
Vue.use(VueNumeric);
Vue.use(money, { precision: 4 });
Vue.use(BootstrapVue);
Vue.use(Clipboard);
Vue.use(Chat);
Vue.use(IconsPlugin);
Vue.use(Modal);
// Vue.use(VueRecord);
// Vue.use(Live);

Vue.use(FFmpeg);
Vue.use(CKEditor);
Vue.use(Chat);
// Vue.use(Clipboard);
Vue.use(Toast);
// Vue.use(Tooltip);
Vue.use(VModal, { componentName: 'vuejs-modal', dialog: true });
Vue.use(VueMask);
Vue.use(VTooltip);
Vue.use(VueEasyLightbox);
Vue.use(Stripo);
Vue.use(Rebranding);
// Vue.use(ColorPicker, { componentName: 'color-picker', name: 'color-picker' });

Vue.component('color-picker', ColorPicker);
Vue.component('apexchart', VueApexCharts);
Vue.component('scrollbar', vueCustomScrollbar, {
  suppressScrollY: false,
  suppressScrollX: false,
  wheelPropagation: false,
});

Vue.directive('dropdown', Dropdown);
Vue.directive('dragglable', Dragglable);
Vue.directive('click-outside', ClickOutside);
Vue.filter('elapsed', ElapsedFilter);
Vue.filter('currency', CurrencyFilter);
Vue.filter('currency4D', CurrencyFilter4D);
Vue.filter('date', Date);
Vue.filter('phone', Phone);
Vue.filter('relativeDate', RelativeDate);
Vue.filter('relativeDateUTC', RelativeDateUTC);
Vue.filter('minifyUuid', MinifyUuid);
Vue.filter('ucfirst', UCFirst);
Vue.filter('basename', Basename);
Vue.filter('fileExtension', FileExtension);
Vue.filter('strToColor', StrToColor);

Vue.filter('maskPhone', (value) => {
  if (value) {
    let phoneNumber = value.replace('(', '');
    phoneNumber = phoneNumber.replace(')', '');
    phoneNumber = phoneNumber.replace(' ', '');
    phoneNumber = phoneNumber.replace('-', '');
    phoneNumber = phoneNumber.replace('+', '');
    phoneNumber = phoneNumber.replace('55', '');
    const formatter = new StringMask('(00) 00000-0000', { reverse: true });
    return formatter.apply(value);
  }
  return null;
});
Vue.filter('filterCNAME', (value) => {
  if (value) {
    const newValue = value.replace('sendgrid', 'yupchat');
    return newValue;
  }
  return null;
});
Vue.filter('formatDate24', (value) => {
  if (value) {
    return moment(value).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm');
  }
  return null;
});
Vue.filter('formatGender', (value) => {
  switch (value) {
    case 'male':
      return 'Masculino';
    case 'female':
      return 'Feminino';
    default:
      break;
  }
  return null;
});
Vue.filter('formatDateHour', (value) => {
  if (value) {
    return moment(String(value)).tz('America/Sao_Paulo').format('DD/MM/YYYY hh:mm');
  }
  return null;
});
Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(value).tz('America/Sao_Paulo').format('DD/MM/YYYY');
  }
  return null;
});
Vue.filter('formatUtc', (value) => {
  if (value) {
    return moment.utc(value).format('DD/MM/YYYY HH:mm');
  }
  return null;
});

if (store.state.account.id && store.state.auth.token) {
  store.dispatch('account/select', store.state.account.id);
}

Vue.config.productionTip = false;

Vue.prototype.$live = LiveService;
Vue.prototype.$notification = NotificationService;
Vue.prototype.$whatsAppMetaAccounts = WhatsAppMetaAccounts;

Vue.prototype.$uuid = {
  generate() {
    const u = window.Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    return [u.substr(0, 8), u.substr(8, 4), `4000-8${u.substr(13, 3)}`, u.substr(16, 12)].join('-');
  },
};

Vue.prototype.$strToColor = StrToColor;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBzlLYISGjL_ovJwAehh6ydhB56fCCpPQw',
    v: '3.26',
  },
  // Demonstrating how we can customize the name of the components
  installComponents: true,
});

const context = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

store.subscribe((mutation, state) => {
  if (mutation.type && mutation.type.indexOf('auth/') === 0) {
    const refresh = context?.$api?.refreshToken;

    if (refresh) refresh(mutation?.payload);
  }
});
