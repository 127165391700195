import RCSDetail from '../views/rcs/Detail.vue';

const routes = [
  {
    name: 'RCS',
    path: '/',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Dashboard.vue'),
  },
  {
    name: 'RCSRequest',
    path: 'request',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Index.vue'),
  },
  {
    name: 'RCSInsight',
    path: 'insights',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Insights.vue'),
  },
  {
    name: 'RCSDenyList',
    path: 'denylist',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/rcs/Blacklist.vue') : import('../views/rcs/Blacklist.vue')),
  },
  {
    name: 'RCSoOptouts',
    path: 'optouts',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Optouts.vue'),
  },
  {
    name: 'RCSDetail',
    path: 'history/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: RCSDetail,
  },
  {
    name: 'RCSCampaigns',
    path: 'campaigns',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Campaigns.vue'),
  },
  {
    name: 'RCSCampaignsDetail',
    path: 'campaigns/:id',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/CampaignDetail.vue'),
  },
  {
    name: 'RCSTemplates',
    path: 'templates',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Templates.vue'),
  },
  {
    name: 'RCSNewTemplates',
    path: 'templates/new',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/rcs/NewTemplate.vue'),
  },
  {
    name: 'EditTemplateRCS',
    path: 'templates/edit',
    meta: {
      role: 'admin,user,owner,sales',
    },
    component: () => import('../views/rcs/NewTemplate.vue'),
  },
  {
    name: 'RCSHistory',
    path: 'history',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/History.vue'),
  },
  {
    name: 'RCSSend',
    path: 'send',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => (window.$rebranding.active ? import('../views/rebranding/rcs/Send.vue') : import('../views/rcs/Send.vue')),
  },
  {
    name: 'RCSSuccess',
    path: 'success',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rcs/Success.vue'),
  },
  {
    name: 'RcsReports',
    path: 'reports',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/rcs/reports/Index.vue'),
    children: [
      {
        name: 'RcsStatReport',
        path: 'stat',
        component: () => import('@/views/rebranding/rcs/reports/Stat.vue'),
      },
      {
        name: 'RcsHistoryReport',
        path: 'history',
        component: () => import('@/views/rebranding/rcs/reports/History.vue'),
      },
      {
        name: 'RcsDetail',
        path: 'history/:id',
        component: () => import('@/views/rebranding/rcs/reports/Detail.vue'),
        meta: {
          role: 'admin,user,owner,billing,developer,sales',
        },
      },
      {
        name: 'RcsUsageReport',
        path: 'usage',
        component: () => import('@/views/rebranding/rcs/reports/Usage.vue'),
      },
      {
        name: 'RcsCampaignReport',
        path: 'campaign',
        component: () => import('@/views/rebranding/rcs/reports/Campaign.vue'),
      },
      {
        name: 'RcsReportExports',
        path: 'exports',
        component: () => import('@/views/rebranding/rcs/reports/Export.vue'),
      },
    ],
  },
  {
    name: 'RCSAgents',
    path: 'agents',
    meta: {
      role: 'admin,user,owner,billing,developer,sales',
    },
    component: () => import('../views/rebranding/rcs/agents/Index.vue'),
  },
];

export default routes;
